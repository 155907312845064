import React from "react";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

const Tracking = () => {
  const { REACT_APP_META_PIXEL, REACT_APP_PROD_HOST, REACT_APP_GOOGLE_TAG } =
    process.env;
  if (window.location.hostname === REACT_APP_PROD_HOST) {
    const advancedMatching = {
      // em: "some@email.com",
    }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(REACT_APP_META_PIXEL, advancedMatching, options);
    ReactPixel.pageView();
    const tagManagerArgs = {
      gtmId: REACT_APP_GOOGLE_TAG,
      dataLayerName: "PageDataLayer",
    };

    TagManager.initialize(tagManagerArgs);
  }

  return <></>;
};

export default Tracking;

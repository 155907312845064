import React from "react";
import { Container, Header, Card, Grid, Button } from "semantic-ui-react";

export default function NotFound() {
  return (
    //  <Header>Page Not Found</Header>;

    <Grid.Row centered>
      <Card>
        <Card.Content>
          <Card.Header content="Something's Wrong Here" />
          <Card.Meta content="404 Not Found" />
          <Card.Description content="We can't find the page you're looking for. Head back home." />
        </Card.Content>
        <Card.Content extra>
          <Button as={"a"} href="/">
            Home
          </Button>
        </Card.Content>
      </Card>
    </Grid.Row>
  );
}

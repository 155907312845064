import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid } from 'semantic-ui-react';

import './index.css';
import App from './App';

import NotFound from './pages/NotFound';
import About from './pages/about/About';
import Philosophy from './pages/philosophy/Philosophy';
import Services from './pages/services/Services';
import ServiceInfo from './pages/services/ServiceInfo';
import AboutInfo from './pages/about/AboutInfo';
import ContactForm from './pages/contact/ContactForm';
import Blog from './pages/Blog';
import Landing from './pages/landing/Landing';
import Footer from './components/Footer/Footer';
import DisplayMarkdown from './utils/DisplayMarkdown';
import privacyMDPath from './pages/privacy.md';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<App />}>
        <Route path="" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/about/:id" element={<AboutInfo />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/philosophy" element={<Philosophy />} />
        <Route path="/services/:slug" element={<ServiceInfo />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route
          path="/privacy"
          element={<DisplayMarkdown markdownPath={privacyMDPath} />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route
        exact
        path="/landing"
        element={
          <Grid columns={1}>
            <Landing />
            <Grid.Row color="black">
              <Grid.Column>
                <Footer />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Header, Image, Grid, Container, Rail } from 'semantic-ui-react';
import svcData from './serviceData';
import './style.css';
import { Helmet } from 'react-helmet';
import useWindowDimensions from '../../utils/useWindowDimensions';

const headerStyle = {
  fontFamily: 'Playfair Display',
  fontStyle: 'italic',
  fontWeight: 'normal',
  fontSize: '64px',
  lineHeight: '137.6%',
  letterSpacing: '0.03em',
};
const textStyle = {
  fontFamily: 'Varta',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '18px',
  lineHeight: '150%',
  /* or 37px */
  letterSpacing: '-0.015em',
};

const paddingStyle = { paddingLeft: '12%', paddingRight: '12%' };

const quoteStyle = {
  position: 'absolute',
  fontFamily: 'Caramel',
  fontStyle: 'cursive',
  fontWeight: 'normal',
  fontSize: '35px',
  lineHeight: '150%',
  display: 'flex',
  alignItems: 'flex-end',
  letterSpacing: '-0.015em',
  color: '#CDB86D',
  paddingTop: '6%',
  paddingLeft: '10%',
};
export default function Services() {
  const [services, setServices] = useState(svcData);
  const { REACT_APP_PROD_HOST } = process.env;
  const { height, width } = useWindowDimensions();
  return (
    <Grid stackable>
      <Helmet>
        <meta
          property="og:url"
          content={`https://${REACT_APP_PROD_HOST}/services`}
        />
        <meta
          property="og:site_name"
          content="C'est si Bon Events - Services"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Grid.Row
        columns={2}
        style={{
          backgroundColor: '#E5E5E5',
          paddingBottom: '3em',
          paddingLeft: '1em',
          minHeight: '350px',
          ...paddingStyle,
        }}
      >
        {width > 480 && (
          <Rail position="right" internal>
            <Container>
              <Image src="/quote-bg2.svg" style={{ position: 'absolute' }} />
              <blockquote style={quoteStyle}>
                “All things come together in the end."
              </blockquote>
            </Container>
          </Rail>
        )}

        <Grid.Column>
          <Header
            size="huge"
            className="headerStyle"
            style={{ paddingTop: '5%', ...headerStyle }}
          >
            What we Offer
          </Header>

          <p style={textStyle}>
            C’est Si Bon Events offers day-of coordination, decor, design, and
            comprehensive planning services so your big day is a collection of
            moments and memories, not hassles or worries. Want to be more
            involved in the moment? We’ve got you covered. We also do
            consultations, and any of our services come a la carte. Just tell us
            how little or much help you need, and we’ll be with you through
            every chapter.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        columns={services.length % 3 === 0 ? 3 : 2}
        verticalAlign="middle"
        style={{ paddingTop: '5%', ...paddingStyle }}
      >
        {services.map((service) => {
          return (
            <Grid.Column key={service._id} textAlign="center">
              <Container textAlign="center">
                <Link
                  to={`/services/${service.slug}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Image size="large" src={service.coverImage} />
                  <Header style={{ ...headerStyle, ...{ fontSize: '35px' } }}>
                    {service.name}
                  </Header>
                </Link>
                <p style={{ ...textStyle, ...{ fontSize: '19px' } }}>
                  {service.description}
                </p>
              </Container>
            </Grid.Column>
          );
        })}
      </Grid.Row>
    </Grid>
  );
}

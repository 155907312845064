import React, { useState } from "react";
import { useEffect } from "react";
import { Grid, Image, Modal, Button, Icon } from "semantic-ui-react";
import "./Carousel.css";

export default function ImageCarousel({ images, sidebarNum = 3 }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const [open, setOpen] = React.useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = images.length - 1;
    } else if (newIndex >= images.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };


  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 2000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });
  return (
    <>
      <Grid columns={2} padded>
        <Grid.Column>
          <Icon.Group size="big">
            <Image
              size="huge"
              src={images[activeIndex].url}
              onMouseEnter={() => setPaused(true)}
              onMouseLeave={() => setPaused(false)}
            />
            <Modal
              onClose={() => {
                setOpen(false);
                setPaused(false);
              }}
              onOpen={() => {
                setOpen(true);
                setPaused(true);
              }}
              open={open}
              trigger={<Icon name="expand" corner="bottom right" inverted />}
            >
              <Modal.Header>{images[activeIndex].title}</Modal.Header>
              <Modal.Content image>
                <Image size="massive" src={images[activeIndex].url} />
                <Modal.Description>
                  <p>{images[activeIndex].credit}</p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setPaused(false);
                  }}
                >
                  Close
                </Button>
              </Modal.Actions>
            </Modal>
          </Icon.Group>
        </Grid.Column>
        <Grid.Column
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {images.map((image, index) => {
            return (
              <Image
                key={index}
                size="tiny"
                spaced
                src={image.url}
                // onClick={() =>
                //   setActiveIndex(setCollageIndex(activeIndex, num))
                // }
              />
            );
          })}
        </Grid.Column>
      </Grid>
    </>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import { profiles } from "./profiles";
import {
  Header,
  Image,
  Grid,
  GridColumn,
  Placeholder,
} from "semantic-ui-react";

import "./style.css";

export default function AboutInfo() {
  const { id } = useParams();
  const { name, description, imageUrl } = profiles.find((p) => p._id === id);
  const index = profiles.findIndex((p) => p._id === id);
  return (
    <Grid reversed={index % 2 !== 0 ? "computer tablet mobile" : ""}>
      <Grid.Row
        columns={2}
        style={{
          padding: "50px 50px 50px 50px",
        }}
        verticalAlign="top"
      >
        <Grid.Column textAlign="justified">
          <Header size="huge">{name}</Header>
          <p>{description}</p>
        </Grid.Column>
        <GridColumn>
          {imageUrl ? (
            <Image size="large" src={imageUrl} />
          ) : (
            <Placeholder fluid>
              <Placeholder.Image style={{ width: "450px", height: "450px" }} />
            </Placeholder>
          )}
        </GridColumn>
      </Grid.Row>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import { List, Grid, Image, Card, Segment } from "semantic-ui-react";
import { Helmet } from "react-helmet";

function ContactForm() {
  const { REACT_APP_PROD_HOST } = process.env;
  const contactInfo = {
    phone: "757-967-7757",
    email: "celebrate@cestsibonevents.com",
  };
  return (
    <Grid stackable centered>
      <Helmet>
        <meta
          property="og:url"
          content={`https://${REACT_APP_PROD_HOST}/contact`}
        />
        <meta property="og:site_name" content="C'est si Bon Events - Contact" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
      </Helmet>

      <Grid.Row columns={1}>
        <Card>
          <Card.Content header="Contact Info" />
          <Card.Content>
            <List>
              <List.Item>
                <List.Icon name="call" size="large" />
                <List.Content>{contactInfo.phone}</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="mail" size="large" />
                <List.Content>
                  <a href={`mailto:${contactInfo.email}`}>
                    {contactInfo.email}
                  </a>
                </List.Content>
              </List.Item>
            </List>
          </Card.Content>
        </Card>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Image.Group size="medium">
          <Image src="/images/zach_corissa_4.jpg" />
          <Image src="/images/candlelighting.jpg" />
          <Image src="/images/decor1.jpg" />
          <Image src="/images/decor3.jpg" />
          <Image src="/images/decor4.jpg" />
          <Image src="/images/decor2.jpg" />
        </Image.Group>
      </Grid.Row>
    </Grid>
  );
}

export default ContactForm;

import React from "react";
import ReactMarkdown from "react-markdown";
import { coordination, planning, decor } from "./svcDescriptions";
const data = [
  {
    _id: "1",
    name: "Design",
    slug: "design",
    coverImage: "/images/decor_diamond.png",
    description:
      "From linens to florals, lights to favors, we have what you need to set the mood.",
    carouselText: <ReactMarkdown children={decor} />,
    images: [
      {
        title: "The stuff",
        credit: "",
        url: "/images/design1.jpg",
      },
      {
        title: "",
        credit: "",
        url: "/images/design2.jpg",
      },
      {
        title: "",
        credit: "",
        url: "/images/design3.jpg",
      },
    ],
  },
  {
    _id: "2",
    name: "Planning",
    slug: "planning",
    coverImage: "/images/planning_diamond.png",
    description:
      "Don't know where to start? We can be there all the way until the finish line.",
    carouselText: <ReactMarkdown children={planning} />,
    images: [
      {
        title: "D",
        credit: "",
        url: "/images/planning1.png",
      },
      {
        title: "E",
        credit: "",
        url: "/images/planning2.jpg",
      },
      {
        title: "F",
        credit: "",
        url: "/images/planning3.jpg",
      },
    ],
  },
  {
    _id: "3",
    name: "Coordination",
    slug: "coordination",
    coverImage: "/images/coordination_diamond.png",
    description:
      "Too many vendors, too little time? Let us handle it, expert to expert.",
    carouselText: <ReactMarkdown children={coordination} />,
    images: [
      {
        title: "F",
        credit: "",
        url: "/images/coordination1.jpg",
      },
      {
        title: "G",
        credit: "",
        url: "/images/coordination2.jpg",
      },
      {
        title: "H",
        credit: "",
        url: "/images/coordination3.jpg",
      },
    ],
  },
];

export default data;

import React, { useEffect } from "react";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Container } from "semantic-ui-react";

const DisplayMarkdown = ({ markdownPath }) => {
  const [readable, setReadable] = useState({ md: "" });
  useEffect(() => {
    fetch(markdownPath)
      .then((res) => res.text())
      .then((md) => {
        setReadable({ md });
      });
  }, [markdownPath]);
  return (
    <Container>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={readable.md} />;
    </Container>
  );
};

export default DisplayMarkdown;

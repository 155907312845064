import ReactMarkdown from "react-markdown";
import { alainabio, markitabio } from "./staffBios";

export const profiles = [
  {
    _id: "1",
    header: "Meet Markita",
    imageUrl: "/images/markitabiopage.jpg",
    name: "Markita Van De Riet",
    path: "/about/1",
    description: <ReactMarkdown children={markitabio} />,
  },
  {
    _id: "2",
    header: "Meet Alaina",
    name: "Alaina Henriquez",
    imageUrl: "/images/alainabiopage.png",
    path: "/about/2",
    description: <ReactMarkdown children={alainabio} />,
  },
];

import React from "react";

const divStyle = {
  position: "relative",
  width: "100%",
  height: 0,
  paddingTop: "150%",
  paddingBottom: 0,
  boxShadow: "0 2px 8px 0 rgba(63, 69, 81, 0.16)",
  overflow: "hidden",
  borderRadius: "8px",
  willChange: "transform",
};

const iframeStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  border: "none",
  padding: 0,
  margin: 0,
};

const Blog = () => {
  return (
    <div style={divStyle}>
      <iframe
        title="Blog"
        loading="lazy"
        style={iframeStyle}
        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFrcv-9_-o&#x2F;view?embed"
      ></iframe>
    </div>
  );
};

export default Blog;

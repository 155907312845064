import React, { useState } from "react";
import { Button, Header, Image, Grid, Segment } from "semantic-ui-react";

const ServiceCarousel = ({ header, images, text }) => {
  const [currentImageIdx, setCurrentImagIdx] = useState(0);

  const prevSlide = () => {
    // find out whether currentImageIdx equals 0 and thus user reached beginning of carousel
    const resetToVeryBack = currentImageIdx === 0;

    const index = resetToVeryBack ? images.length - 1 : currentImageIdx - 1;

    // assign the logical index to current image index that will be used in render method
    setCurrentImagIdx(index);
  };

  const nextSlide = () => {
    // check if we need to start over from the first index
    const resetIndex = currentImageIdx === images.length - 1;

    const index = resetIndex ? 0 : currentImageIdx + 1;

    // assign the logical index to current image index that will be used in render method
    setCurrentImagIdx(index);
  };

  // create a new array with 5 elements from the source images
  const activeImageSourcesFromState = images.slice(
    currentImageIdx,
    currentImageIdx + 3
  );

  // check the length of the new array (it’s less than 5 when index is at the end of the image sources array)
  const imageSourcesToDisplay =
    activeImageSourcesFromState.length < 3
      ? // if the imageSourcesToDisplay's length is lower than 5 images than append missing images from the beginning of the original array
        [
          ...activeImageSourcesFromState,
          ...images.slice(0, 3 - activeImageSourcesFromState.length),
        ]
      : activeImageSourcesFromState;

  return (
    <Grid container>
      <Grid.Row columns={1}>
        {header && (
          <Header className="headerStyle" size="huge" content={header} />
        )}

        <Segment className="inner" textAlign="center">
          {/* render images */}
          {imageSourcesToDisplay.map((image, index) => {
            return (
              <Image
                key={index}
                size="medium"
                inline
                spaced="right"
                src={image["url"]}
              />
            );
          })}
        </Segment>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column textAlign="right">
          <div>
            <Button circular onClick={prevSlide}>
              Prev
            </Button>
            <Button circular onClick={nextSlide}>
              Next
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>{text}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ServiceCarousel;

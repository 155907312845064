import React from 'react';

import { Header, Grid, Image } from 'semantic-ui-react';
import ImageCarousel from '../../components/Carousel/ImageCarousel';
import ProfileCard from '../../components/Profile/ProfileCard';
import './style.css';

import { profiles } from './profiles';
import { Helmet } from 'react-helmet';
const textStyle = {
  fontFamily: 'Varta',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '18px',
  lineHeight: '150%',
  letterSpacing: '-0.015em',
};
const paddingStyle = { paddingLeft: '12%', paddingRight: '12%' };
const images = [
  {
    title: '',
    credit: '',
    url: '/images/who_we_are1.jpg',
  },
  {
    title: '',
    credit: '',
    url: '/images/who_we_are2.jpg',
  },
  {
    title: '',
    credit: '',
    url: '/images/alainaandmarkita.jpg',
  },
];
const About = () => {
  const { REACT_APP_PROD_HOST } = process.env;
  return (
    <Grid stackable>
      <Helmet>
        <meta
          property="og:url"
          content={`https://${REACT_APP_PROD_HOST}/about`}
        />
        <meta property="og:site_name" content="C'est si Bon Events - About" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Image src="/images/banner.png" fluid />
      <Grid.Row className="bg" columns={1} style={paddingStyle}>
        <Grid.Column>
          <Header
            size="large"
            className="headerStyle"
            style={{ paddingTop: '5%' }}
          >
            Who We Are
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        columns={2}
        className="bg"
        style={{ paddingBottom: '5%', ...paddingStyle }}
      >
        <Grid.Column width={8}>
          <ImageCarousel images={images} />
        </Grid.Column>
        <Grid.Column width={6}>
          <p style={textStyle}>
            C’est Si Bon Events is the work of Markita Van de Riet and Alaina
            Henriquez. Founded officially in 2015, the unlikely pair were making
            magic years before. With love in the air, and plenty of experience
            in her belt, Markita set out to plan her own wedding in 2006. She
            found it hard to both celebrate and plan, relax and coordinate,
            enjoy and design. Alaina stepped in to save the day, and their
            chemistry was undeniable. Alaina’s eye for style, moments, and
            memories pair like a dream to Markita’s strategy and vision.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column style={{ padding: '5%' }}>
          <ProfileCard profiles={profiles} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default About;

export const coordination = `## DAY OF WEDDING COORDINATION SERVICES

### General Information

Unlike a full-service wedding coordinator, a “Day-of” Coordinator is for brides and grooms who want to do most of the planning themselves, but when their wedding day arrives, they need a professional to execute all of their hard work.

Too often, brides rely on family and friends to help carry out plans on the day of their wedding.  By asking a friend or family member to take on this stressful task, that person will not be able to enjoy the event as a guest, and will end up feeling like “event staff.”  

In most cases, your “volunteers” have little experience in the role of event planner.  They often end up feeling overwhelmed as they try to keep on top of everything that must be done to ensure that the day of the wedding runs smoothly.  The following information breaks down what you should expect as a general timeline and details/expectations for a “Day-of-Coordinator.”

### Two Weeks Prior to Wedding Day

Meet with the bride and groom to discuss the timeline.  Go over any arrangements the couple has previously made with vendors and update the timeline accordingly.  If there are any items the coordinator finds the couple has missed/overlooked during their planning process it will be presented to them at this time.  It will be the responsibility of the Bride/Groom to secure these requested items prior to the next meeting in order for all aspects of the wedding day to flow properly.

### One Week Prior to Wedding Day

Confirm final details with vendors prior to wedding day.  No further negotiations will be made by the coordinator.  The coordinators responsibility then is only to manage what the couple has previously put in place and to make sure that all vendors have coordinators contact information.  Vendors will also be sent a copy of the final timeline and make sure they have directions to the ceremony and reception locations.  

Please Again Note: A day-of-coordinator will not re-negotiate any terms in contracts with vendors at this time, or at any other time prior to, during, or after your wedding day.  All agreements should be finalized prior to the wedding.


### Rehearsal Responsibilities

Distribute wedding timelines to best man and maid-of honor
Work with wedding officiator to choreograph wedding ceremony, processional and recessional
Alert wedding party as to where and at what time they need to arrive the following day.


### Ceremony/Reception Responsibilities

Communicate all pre-wedding/wedding details to the bride, groom, bridal party and vendors as well as facilitate interactions, timelines and or changes.  

Coordinate the wedding from the beginning to the end of the wedding, ensuring the venue is set-up and ready for your perfect day.

Ensure the venue and vendors have what they need to keep everything flowing smoothly.

Assist with breaking down and manage the clean up process.`;

export const planning = `The C’est si Bon full wedding package includes the following pre-wedding/wedding planning, coordination and reception services: 
* Event Planner/Coordinator Services:  Consultation, Coordination & On-site Management for wedding rehearsal/dinner and the day of the wedding.
* Needs Assessment & Consultation:  Initial meeting with you to discuss & define the fundamental objective underlying your investment of time, energy and resources required to produce the event, and subsequently provide you with an event framework that most directly, efficiently and effectively achieves these objectives.
* Correspondence:  Review and respond to the correspondence that you receive relative to your wedding.
* Document Preparation:  This can include creating documents, spreadsheets or presentations for your event.
* Coordination assistance with sending out Save the Date and wedding invitations 
* Wedding planning status meetings (monthly and/or as needed)
* Assistance with seat assignment at reception
* Venue: Consultation, Coordination & On-site Management on the day of the event.
* Rentals: Consultation, Coordination & On-site Management on the day of the event.
* Music and Entertainment: Consultation, Coordination & On-site Management on the day of the event.
* Catering: Consultation, Coordination & On-site Management on the day of the event.
* Photographer and Cinematographer: Consultation, Coordination & On-site Management on the day of the event.

Coordinate wedding rehearsal and assist with coordinating the rehearsal dinner details

Communicate all pre-wedding/wedding details to the bride, groom, bridal party and vendors as well as facilitate interactions, timelines and or changes. 
`;

export const decor = `## DECOR SERVICES Include:
* Decor Services:  Consultation, Coordination & On-site Management on the day of the event.
* Needs Assessment & Consultation:  Initial meeting to discuss your vision, theme or objective, underlying investment of time, energy and resources required to produce the event.  We’ll also provide a decor framework that will most directly, efficiently and effectively achieve these objectives.
* Correspondence:  Review and respond to any and all questions or requests regarding your event.
* Venue: Consultation, Décor Coordination & On-site Management on the day of event.
* Rentals: Consultation, Coordination & On-site Management on the day of the event.
* Purchases: We will consult and obtain your approval before any purchases are made on your behalf.
`;

import React from "react";
import { Menu, Button, List } from "semantic-ui-react";
const SocialLinksList = ({ social, ...rest }) => {
  return (
    <List {...rest}>
      {social.map((soc) => {
        return (
          soc.link && (
            <List.Item key={soc.name}>
              <Button
                circular
                color={soc.color}
                icon={soc.icon}
                href={soc.link}
                target="blank"
              />
            </List.Item>
          )
        );
      })}
    </List>
  );
};

export default SocialLinksList;

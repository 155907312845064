export const markitabio = `My name is Markita Van de Riet (it’s Dutch, pronounced Van - de- Reet)

There is nothing like helping a person or couple plan a great event or wedding regardless of the size.  It’s the adrenaline rush, themes, colors, and vision that invokes so much joy inside of me.  I’ve had the pleasure of planning a variety of events and weddings for more than 15 years.  I actually got my first taste of planning events as a part of a multicultural commission in high school.  Our team was tasked with helping foreign exchange students integrate into our school through planned social parties. I enjoyed watching people who seemingly had nothing in common have a great time.   

I attribute my easy, breezy, love of parties to being raised in San Diego County. It wasn’t until I landed my dream job working in the marketing department of a large church that planning events became my full-time job; it’s also where I met Alaina.   I was responsible for planning and coordinating large conferences, speaking engagements, book signings and networking meet and greets (the list goes on but I don’t want to bore you).  It wasn’t until I got married that Alaina and I really bonded and started a side gig planning events/weddings.  

After I left that church and started working for a major newspaper publishing company, I was given an opportunity to plan the annual holiday party.  The party was such a hit, I became “thee” party planner for my job for seven years and trust me they liked to party.

The joy and fulfillment I get from figuring out the logistics of an event is indescribable. I would plan events forever if I could, especially with my amazing business partner, who makes what I do that much more rewarding.   I sincerely believe God’s purpose for my life is to help people create joyous moments that build and strengthen relationships.`;

export const alainabio = `Hi, I’m Alaina Henriquez from Gonzales, Louisiana.  I started out as an elementary school teacher, a job I loved before working at a large church part-time as an accountant.  I also assisted Markita with large conferences, speaking engagements and other events.  I left my teaching and accountant job to start a catering and later a restaurant called Terebinth.  Sadly a few years later my business partner and I had to close the restaurant.  But when one door closes, God opens a window and friends started asking me to help them plan and decorate for their birthday parties.   Without hesitation, I reached out to Markita and asked her if she wanted to start planning events on the side which soon led to weddings, catering small events, and other types of parties.  Combined with my love for planning events and decor, I love fitness and started a business teaching a hip-hop style cardio class called Mixxed Fit.  From there, I opened up a gym studio call Drop Fitness (Demanding Results On Purpose).  By having a physical location, we’re able to provide a space for clients who are looking for a small venue at a reasonable cost.  I love the creative process of making an event visually stunning without paying high-end prices.  I have a favorite saying, “We can make it work”.  I say it a lot because at the end of the day, we believe passionately in what we do and we’re not easily deterred when things don’t exactly go the way we planned.  In Louisiana we have a saying, laissez le bon temps rouler (Let the good times roll) which is what we want our clients to feel they can do because they know C’est Si Bon Events is already taking care of it.`;

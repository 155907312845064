import React from "react";
import { Icon } from "semantic-ui-react";
import Moment from "react-moment";
import moment from "moment";
const Copyright = ({ org }) => {
  return (
    <span>
      <Icon size="small" name="copyright outline" />
      {org} <Moment format="YYYY">{moment()}</Moment>.
    </span>
  );
};

export default Copyright;

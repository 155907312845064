import React from "react";
import { List } from "semantic-ui-react";
import { v4 as uuid4 } from "uuid";

const FooterLinksList = ({ links, ...rest }) => {
  return (
    <List {...rest}>
      {links.map((link) => (
        <List.Item key={uuid4()} as={"a"} href={link.url}>
          {link.name}
        </List.Item>
      ))}
    </List>
  );
};

export default FooterLinksList;

import React from "react";
import { Link } from "react-router-dom";
import { Card, Image, Header, Placeholder } from "semantic-ui-react";

const ProfileCard = ({ profiles }) => {
  return (
    <Card.Group centered itemsPerRow={3} stackable>
      {profiles.map((profile, index) => {
        return (
          <Card
            key={`${profile.name}_${index}`}
            as={Link}
            to={profile.path}
            style={{ margin: "2%" }}
          >
            <Card.Content>
              {profile.imageUrl ? (
                <Image
                  size="large"
                  centered
                  style={{ maxHeight: "500px" }}
                  src={profile.imageUrl}
                />
              ) : (
                <Placeholder fluid>
                  <Placeholder.Image rectangular />
                </Placeholder>
              )}
            </Card.Content>
            <Card.Content>
              <Header
                textAlign="center"
                style={{ fontFamily: "Playfair Display", fontStyle: "italic" }}
              >
                {profile.header}
              </Header>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
  );
};

export default ProfileCard;

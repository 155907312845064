import React, { useState } from 'react';
import {
  Container,
  Divider,
  Grid,
  Label,
  List,
  Segment,
} from 'semantic-ui-react';
import SocialLinksList from './SocialMedia';
import Copyright from './Copyright';
import FooterLinksList from './Links';

export default function Footer() {
  const [socMenu, setSocMenu] = useState([
    {
      name: 'facebook',
      color: 'facebook',
      icon: 'facebook f',
      link: 'https://www.facebook.com/cestsibonevents/',
    },
    {
      name: 'instagram',
      color: 'instagram',
      icon: 'instagram',
      link: 'https://www.instagram.com/cestsibonevents/',
    },
    { name: 'linkedin', color: 'linkedin', icon: 'linkedin', link: '' },
    {
      name: 'pinterest',
      color: 'red',
      icon: 'pinterest',
      link: 'https://www.pinterest.com/cestsibonevents/',
    },
  ]);

  return (
    <Container fluid>
      <Grid columns={3}>
        <Grid.Column>
          <FooterLinksList
            horizontal
            links={[{ name: 'Privacy Policy', url: '/privacy' }]}
          />
        </Grid.Column>
        <Grid.Column>
          <Copyright org="C'est si Bon Events" /> We love our users!
        </Grid.Column>
        <Grid.Column>
          <SocialLinksList horizontal floated="right" social={socMenu} />
        </Grid.Column>
      </Grid>
      <Label size="mini" as="a" href="mailto:contact@ceratopsdev.com">
        Powered by Ceratops Developers
      </Label>
    </Container>
  );
}

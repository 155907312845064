import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Menu, Image } from "semantic-ui-react";

export default function Header({ menu = [] }) {
  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (e, { name }) => setActiveItem(name);
  return (
    // <header>
    <Menu
      attached
      inverted
      className="menuStyle"
      style={{ paddingLeft: "6%", paddingRight: "6%" }}
      stackable
    >
      <Menu.Item
        header
        style={{ paddingBottom: "1em" }}
        onClick={() => setActiveItem(undefined)}
        as={Link}
        to="/"
      >
        <Image src="/logo.png" size="medium" />
      </Menu.Item>
      {menu.map((menuItem) => {
        return (
          <Menu.Item
            key={menuItem.name}
            as={NavLink}
            name={menuItem.name}
            to={menuItem.path}
            active={activeItem === menuItem.name}
            onClick={handleItemClick}
            position={menuItem.name === menu[0].name ? "right" : undefined}
          />
        );
      })}
    </Menu>
    // </header>
  );
}

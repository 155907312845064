import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import './App.css';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Tracking from './utils/Analytics';


function App() {
  return (
    <Grid>
      <Tracking />
      <Helmet>
        <title>C'est si Bon Events</title>
        <meta
          name="facebook-domain-verification"
          content={process.env.REACT_APP_FACEBOOK_CODE}
        />
      </Helmet>
      <Grid.Row columns={1}>
        <Header
          menu={[
            {
              path: '/about',
              name: 'About',
            },
            {
              path: '/philosophy',
              name: 'Philosophy',
            },
            {
              path: '/blog',
              name: 'Blog',
            },
            {
              path: '/contact',
              name: 'Contact',
            },
          ]}
        />
      </Grid.Row>
      <Outlet />
      <Grid.Row color="black" columns={1}>
        <Grid.Column>
          <Footer />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default App;

import React, { createContext, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Button,
  Container,
  Header,
  Image,
  Sticky,
  Rail,
  Menu,
  Label,
} from "semantic-ui-react";

import "./style.css";
import { footerCxt } from "../../App";
const landingStyle = {
  backgroundImage: `url("/images/landing.jpg")`,
  backgroundSize: "cover",
  minHeight: "800px",
  display: "flex",
  flexDirection: "column",
};

export default function Landing() {
  return (
    <Container fluid style={landingStyle}>
      <Header className="meddon">Create & Imagine</Header>
      <Card
        style={{
          width: "25rem",
          marginLeft: "13%",
          "--bs-bg-opacity": ".75",
          marginTop: "auto",
        }}
        className="bg-dark bg-gradient notch"
      >
        <Card.Content textAlign="center">
          <Card.Header className="meddon" style={{ color: "white" }}>
            C'est si Bon Events
          </Card.Header>
          <Card.Description style={{ color: "white" }}>
            Welcome to C'est Si Bon Events. We're thrilled to have the
            opportunity to be a part of your story. Let's make this happily ever
            after.
          </Card.Description>
          <Card.Content>
            <Button
              as={Link}
              to="/about"
              style={{ backgroundColor: "transparent" }}
              className="border border-warning"
            >
              <Header style={{ color: "white" }}>Enter</Header>
            </Button>
          </Card.Content>
        </Card.Content>
      </Card>
    </Container>
  );
}

import React from 'react';
import { Header, Image, Grid, Container, Rail } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import './style.css';
import useWindowDimensions from '../../utils/useWindowDimensions';

const textStyle = {
  fontFamily: 'Varta',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '18px',
  lineHeight: '150%',
  /* or 37px */
  letterSpacing: '-0.015em',
};

const paddingStyle = { paddingLeft: '12%', paddingRight: '12%' };

const quoteStyle = {
  position: 'absolute',
  fontFamily: 'Caramel',
  fontStyle: 'cursive',
  fontWeight: 'normal',
  fontSize: '35px',
  lineHeight: '150%',
  display: 'flex',
  alignItems: 'flex-end',
  letterSpacing: '-0.015em',
  color: '#CDB86D',
  paddingTop: '6%',
  paddingLeft: '10%',
};

const srca = '/images/wedding_firstdance_twirl.jpg';
const srcb = '/images/weddingdecor_2019_3.jpg';

export default function Philosophy() {
  const { REACT_APP_PROD_HOST } = process.env;
  const { height, width } = useWindowDimensions();

  return (
    <Grid stackable style={{ minHeight: '500px' }}>
      <Helmet>
        <meta
          property="og:url"
          content={`https://${REACT_APP_PROD_HOST}/philosophy`}
        />
        <meta
          property="og:site_name"
          content="C'est si Bon Events - Philosophy"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Grid.Row
        columns={2}
        style={{
          backgroundColor: '#E5E5E5',
          paddingBottom: '3em',
          paddingLeft: '1em',
          minHeight: '350px',
          ...paddingStyle,
        }}
      >
        {width > 480 && (
          <Rail position="right" internal>
            <Container>
              <Image src="/quote-bg.svg" style={{ position: 'absolute' }} />
              <blockquote style={quoteStyle}>
                “To pay attention, this is our endless and proper work.” - Mary
                Oliver
              </blockquote>
            </Container>
          </Rail>
        )}
        <Grid.Column>
          <Header size="huge" className="headerStyle">
            Our Philosophy
          </Header>
          <Image src={srca} size="small" floated="left" />
          <p style={textStyle}>
            Everyone deserves to write their own story, seen through their eyes
            as it unfolds. Events live in a world of meetings, phone calls,
            scheduling, and design planning. The countless tasks of logistics
            build a barrier, keeping you from living in the moment. We believe
            in providing a peace of mind, and taking hold of the reins. We
            delight in the small details, the big picture, and every moment.
          </p>
          <Image src={srcb} size="small" floated="right" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

import React from 'react';
import { useParams } from 'react-router-dom';
import services from './serviceData';
import ServiceCarousel from '../../components/Carousel/Carousel';
import './style.css';

const ServiceInfo = () => {
  const { slug } = useParams();
  const { images, name, carouselText } = services.find((s) => s.slug === slug);

  return <ServiceCarousel header={name} images={images} text={carouselText} />;
};

export default ServiceInfo;
